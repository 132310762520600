import React from "react";
// Customizable Area Start
import { Box, styled } from "@material-ui/core";
import { leftArrow } from "./assets";
import Loader from "../../../components/src/Loader.web";

// Customizable Area End

import ProductDetailsPageController, {
  Props,
} from "./ProductDetailsLandingPageController";

export default class ProductDetailsLandingPage extends ProductDetailsPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      <>
        <Loader loading={this.state.loading} />
        <ProductDeatilsLandingPageCss>
          <Box className="productDetails-white-container">
            <Box className="back-arrow-box">
              <img
                src={leftArrow}
                alt="leftArrow"
                id="back-arrow"
                className="back-arrow-img"
                onClick={() => this.goBackToProductPage()}
              />
              <Box className="back-arrow-text">Back to results</Box>
            </Box>
            <Box className="productDetails-main-text">{this.state.productDetails.title}</Box>
            <Box className="productDetails-img-main">
              {this.state.productDetails.media.map((item, index) => (
                <Box className="img-loop">
                  <img src={item?.link} height={172} width={166} alt="keyFeatureImg" />
                </Box>
              ))}
            </Box>
            <Box className="productDetails-Id-btn">
              <Box className="productDetails-Id-btn-text">
                {this.state.productDetails.product_id}
              </Box>
            </Box>
            <Box className="phones-name" component={"ul"}>
              {this.state.productDetails.extensions.map((item: string) => (
                <Box component={"li"} className="productDetails-li-text" >
                {item}
              </Box>
              ))}
            </Box>
            <Box>
              <Box className="productDetails-head" id="heading">Specification</Box>
              <Box className="productDetails-h-text">Universal Product Identifies</Box>
              <Box className="productDetails-box">
                <Box>
                  <Box className="productDetails-h-text">Brand</Box>
                  <Box className="productDetails-h-text">Part Number</Box>
                  <Box className="productDetails-h-text">GTIN</Box>
                </Box>
                <Box>
                  <Box className="productDetails-h-text">Brand</Box>
                  <Box className="productDetails-h-text">MGSS34556</Box>
                  <Box className="productDetails-h-text">0012345678998</Box>
                </Box>
              </Box>
            </Box>
            <Box>
              <Box className="productDetails-text-bold" id="heading">
                Description
              </Box>
              <Box className="productDetails-p-text">
                {this.state.productDetails.description}
              </Box>
            </Box>
          </Box>
        </ProductDeatilsLandingPageCss >
      </>
    );
  }
}

// Customizable Area Start
const ProductDeatilsLandingPageCss = styled(Box)({
  "& .productDetails-white-container": {
    padding: "50px 108px",
    background: "#E8E7E7",
    "@media(max-width:1050px)": {
      padding: "40px 40px",
    },
    "@media(max-width:920px)": {
      padding: "40px 20px",
    },
  },
  "& .back-arrow-box": {
    marginBottom: "24px",
    alignItems: "center",
    display: "flex",
    gap: 16,
  },
  "& .back-arrow-img": {
    cursor: "pointer"
  },
  "& .back-arrow-text": {
    color: "#212324",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "18px",
  },
  "& .productDetails-main-text": {
    fontSize: "24px",
    fontWeight: 700,
    marginBottom: "24px",
    fontFamily: "Inter",
  },
  "& .box-span": {
    color: "#DF4833"
  },
  "& .productDetails-img-main": {
    flexWrap: "wrap",
    padding: "24px",
    display: "flex",
    gap: 24,
    background: "#F0F0F0",
    height: "172px",
    overflow: "scroll"
  },
  "& .img-loop": {
    width: 166,
    objectFit: "contain",
    height: 172,
  },
  "& .productDetails-Id-btn": {
    background: "#FCEDEB",
    alignItems: "center",
    marginTop: "24px",
    borderRadius: 8,
    justifyContent: "center",
    height: "58px",
    display: "flex",
    padding: "0px 24px",
    width: "fit-content",
    "@media(max-width:480px)": {
      padding: "0px 14px",
    },
    "@media(max-width:380px)": {
      padding: "0px 10px",
    },
  },
  "& .productDetails-Id-btn-text": {
    cursor: "pointer",
    fontWeight: 700,
    fontFamily: "Inter",
    textTransform: "none",
    fontSize: 24,
    color: "#DF4833",
    "@media(max-width:550px)": {
      fontSize: 20,
    },
    "@media(max-width:460px)": {
      fontSize: 16,
    },
    "@media(max-width:380px)": {
      fontSize: 14,
    },
  },
  "& .phones-name": {
    flexWrap: "wrap",
    margin: "24px 0 0 -30px",
    gap: "21px",
    display: "flex",
  },
  "& .productDetails-text-bold": {
    lineHeight: "28px",
    fontWeight: 700,
    fontSize: "20px",
    margin: "20px 0 7px 0",
    color: "#212324",
    fontFamily: "Inter",
  },
  "& .productDetails-p-text": {
    marginBottom: "5px 0 15px 0",
    lineHeight: "28px",
    fontFamily: "Inter",
    fontSize: 18,
    color: "#4F5356",
    fontWeight: 400,
  },
  "& .productDetails-li-text": {
    fontFamily: "Inter",
    fontWeight: 400,
    lineHeight: "28px",
    fontSize: 20,
    marginLeft: "9px",
    color: "#4F5356",
  },
  "& .productDetails-buttons-main-box": {
    gap: 20,
    flexWrap: "wrap",
    display: "flex",
    marginTop: 24,
  },
  "& .productDetails-buttons-box": {
    background: "#F5F5F4",
    cursor: "pointer",
    border: "1px solid #AEB0B1",
    gap: "10px",
    padding: "10px 16px",
    borderRadius: "8px",
    alignItems: "center",
    display: "flex",
  },
  "& .productDetails-buttons-box-red": {
    borderRadius: "8px",
    display: "flex",
    cursor: "pointer",
    gap: "10px",
    background: "#DF4833",
    padding: "10px 16px",
    alignItems: "center",
  },
  "& .productDetails-button-text": {
    fontWeight: 400,
    fontFamily: "Inter",
    fontSize: "16px",
    color: "#4F5356",
  },
  "& .productDetails-button-text-white": {
    fontFamily: "Inter",
    color: "#FFFFFF",
    fontWeight: 400,
    fontSize: "16px",
  },
  "& .productDetails-head": {
    marginTop: "20px",
    fontFamily: "Inter",
    color: "#212324",
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: 700,
  },
  "& .productDetails-h-text": {
    margin: "3px 0",
    color: "#4F5356",
    fontSize: "20px",
    fontWeight: 400,
    fontFamily: "Inter",
    lineHeight: "28px"
  },
  "& .productDetails-box": {
    display: "flex",
    gap: "60px",
  },
});
// Customizable Area End
