import React from "react";
// Customizable Area Start
import { Box, styled } from "@material-ui/core";
import { leftArrow } from "./assets";
import Loader from "../../../components/src/Loader.web";

// Customizable Area End

import ClassificationLandingPageController, {
  Props,
} from "./ClassificationLandingPageController";

export default class ClassificationLandingPage extends ClassificationLandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const classificationsToShow = this.state.viewMoreClassification ? this.state.publicationDetails?.classifications : this.state.publicationDetails?.classifications?.slice(0, 2);
    return (
      <>
      <Loader loading={this.state.loading} />
        <ClassificationLandingPageCss>
          <Box className="classification-white-container">
            <Box className="back-arrow-box">
              <img
                src={leftArrow}
                alt="leftArrow"
                id="back-arrow"
                className="back-arrow-img"
                onClick={() => this.goBackToPublicationPage()}
              />
              <Box className="back-arrow-text">Back to results</Box>
            </Box>
            <Box className="classification-text">{this.state.publicationDetails?.title}</Box>
            <Box className="description-text-box-main">
              <Box className="description-text-bold" id="heading">
                Description
              </Box>
              <Box className="description-p-text">{this.state.publicationDetails?.snippet}</Box>
              <Box className="description-small-text">
                Continue reading at {this.state.publicationDetails?.full_view_url}(PDF) (other versions)
              </Box>
            </Box>
            <Box className="classification-main-box">
              <Box className="classification-Id-Box">
                <Box className="classification-Id-text">{this.state.publicationDetails?.search_parameters?.patent_id}</Box>
              </Box>
              <Box className="classification-box">
                <Box>
                  <Box className="authorStyle">Author</Box>
                  <Box className="author-text">{this.state.publicationDetails?.authors?.join(' ')}</Box>
                </Box>
                <Box>
                  <Box className="publicationYear-Style">Publication year</Box>
                  <Box className="publicationYear-text">{this.state.publicationDetails?.publication_year}</Box>
                </Box>
              </Box>
              <Box className="classification-box">
                <Box>
                  <Box className="authorStyle">Publication venue</Box>
                  <Box className="author-text">2017 26th IEEE International Symposium on Robot and Human Interactive Communication (RO-MAN)</Box>
                </Box>
              </Box>
            </Box>
            <Box>
              <Box className="classification-text-bold">
                Classifications
              </Box>
              <Box component={"ul"} className="classification-ul">
               {classificationsToShow?.map((item:{code: string,description:string},index:number) => (
                 <Box component={"li"} className="classification-li-text" key={index}>
                   <Box component={"span"} className="classification-li-text-red">{item.code}</Box>{item.description} 
                 </Box>
               ))}
              </Box>
              <Box>
                {!this.state.viewMoreClassification ? (
                  <Box className="classification-small-text" id="viewMore" onClick={() => this.viewMoreClassificationFun()}>
                    View More Classification
                  </Box>
                ) : (
                  <Box className="classification-small-text" id="hideMore" onClick={() => this.hideMoreClassificationFun()}>
                    Hide More Classification
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </ClassificationLandingPageCss>
      </>
    );
  }
}

// Customizable Area Start
const ClassificationLandingPageCss = styled(Box)({
  "& .classification-white-container": {
    padding: "50px 108px",
    background: "#E8E7E7",
    "@media(max-width:1050px)": {
      padding: "40px 40px",
    },
    "@media(max-width:920px)": {
      padding: "40px 20px",
    },
  },
  "& .back-arrow-box": {
    gap: 16,
    display: "flex",
    alignItems: "center",
    marginBottom: "24px"
  },
  "& .back-arrow-img": {
    cursor: "pointer"
  },
  "& .back-arrow-text": {
    fontSize: "18px",
    fontFamily: "Inter",
    color: "#212324",
    fontWeight: 400,
  },
  "& .classification-text": {
    marginBottom: "24px",
    fontWeight: 700,
    fontSize: "24px",
    fontFamily: "Inter",
  },
  "& .box-span-red": {
    color: "#DF4833"
  },
  "& .text-box-main": {
    marginTop: 24,
  },
  "& .description-text-bold": {
    color: "#212324",
    fontSize: "20px",
    lineHeight: "28px",
    fontFamily: "Inter",
    marginBottom: "10px",
    fontWeight: 700,
  },
  "& .description-p-text": {
    fontFamily: "Inter",
    marginBottom: "5px 0 15px 0",
    fontWeight: 400,
    lineHeight: "28px",
    color: "#4F5356",
    fontSize: 18,
  },
  "& .description-small-text": {
    fontFamily: "Inter",
    margin: "15px 0",
    fontWeight: 400,
    lineHeight: "28px",
    color: "#727578",
    fontSize: 16,
  },
  "& .classification-main-box": {
    display: "flex",
    gap: "24px",
    marginTop: 24,
    "@media(max-width:1050px)": {
      flexWrap: "wrap",
    },
  },
  "& .classification-Id-Box": {
    minHeight: "170px",
    padding: "24px",
    borderRadius: 8,
    background: "#FCEDEB",
  },
  "& .classification-Id-text": {
    fontWeight: 700,
    color: "#DF4833",
    fontFamily: "Inter",
    fontSize: 24,
    justifyContent: "center",
    height: "100%",
    alignItems: "center",
    display: "flex",
  },
  "& .classification-box": {
    background: "#F5F5F4",
    padding: "24px",
    borderRadius: "8px",
    minHeight: "170px",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    width: "472px",
    gap: "20px",
  },
  "& .authorStyle": {
    fontSize: "20px",
    color: "#DF4833",
    lineHeight: "28px",
    fontWeight: 700,
    fontFamily: "Inter",
  },
  "& .author-text": {
    fontFamily: "Inter",
    fontSize: "18px",
    color: "#4F5356",
    lineHeight: "28px",
    fontWeight: 400,
  },
  "& .publicationYear-Style": {
    fontFamily: "Inter",
    lineHeight: "28px",
    fontWeight: 700,
    color: "#DF4833",
    fontSize: "20px",
  },
  "& .publicationYear-text": {
    lineHeight: "28px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "18px",
    color: "#4F5356",
  },
  "& .classification-text-bold": {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 700,
    color: "#212324",
    margin: "24px 0 7px 0",
    lineHeight: "28px",
  },
  "& .classification-p-text": {
    color: "#4F5356",
    fontSize: 20,
    fontWeight: 400,
    lineHeight: "28px",
    fontFamily: "Inter",
    marginBottom: "5px 0 15px 0",
  },
  "& .classification-small-text": {
    color: "#727578",
    fontSize: 16,
    cursor: "pointer",
    width: "fit-content",
    fontWeight: 400,
    margin: "15px 0",
    lineHeight: "28px",
    fontFamily: "Inter",
  },
  "& .classification-ul": {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    margin: 0
  },
  "& .classification-li-text": {
    color: "#4F5356",
    fontSize: 18,
    fontWeight: 400,
    lineHeight: "28px",
    fontFamily: "Inter",
  },
  "& .classification-li-text-red": {
    color: "#DF4833",
    fontSize: 18,
    fontWeight: 400,
    lineHeight: "28px",
    fontFamily: "Inter",
  },
});
// Customizable Area End
