Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";
exports.chartYear =  ["2005", "2006", "2007", '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018'];
exports.chartData = [
  3500000, 4500000, 3000000, 5000000, 2500000, 3500000,
  3000000, 3500000, 4000000, 2500000, 3000000, 4500000,
  3000000, 4000000,
];
exports.getProfileEndPoint = "account_block/accounts"
exports.generateFeaturesEndPoint = "bx_block_landingpage2/landing_pages/generate_features";
exports.keywordGenerateEndPoint = "bx_block_landingpage2/landing_pages/generate_keyword?key_feature=";
exports.generateStringEndPoint = "bx_block_landingpage2/landing_pages/generate_string?keyword=";
exports.patentSerpApiEndPoint = "bx_block_advanced_search/search";
exports.contactDetailsApiEndPoint = "bx_block_landingpage2/landing_pages";
exports.getDetailsAPIEndPoint = "bx_block_advanced_search/patent?publication_number=";
exports.getArticleAPIEndPoint = "bx_block_landingpage2/articles"
exports.postProductAPiEndPoint = "bx_block_advanced_search/product_search"
exports.patentOffice = [
    { name: "WO" },
    { name: "US" },
    { name: "EP" },
    { name: "JP" },
    { name: "KR" },
    { name: "CN" },
    { name: "AE" },
    { name: "AG" },
    { name: "AL" },
    { name: "AM" },
    { name: "AO" },
    { name: "AP" },
    { name: "AR" },
    { name: "AT" },
    { name: "AU" },
    { name: "AW" },
    { name: "AZ" },
    { name: "BA" },
    { name: "BB" },
    { name: "BD" },
    { name: "BE" },
    { name: "BF" },
    { name: "BG" },
    { name: "BH" },
    { name: "BJ" },
    { name: "BN" },
    { name: "BO" },
    { name: "BR" },
    { name: "BW" },
    { name: "BX" },
    { name: "BY" },
    { name: "BZ" },
    { name: "CA" },
    { name: "CF" },
    { name: "CG" },
    { name: "CH" },
    { name: "CI" },
    { name: "CL" },
    { name: "CM" },
    { name: "CO" },
    { name: "CR" },
    { name: "CS" },
    { name: "CU" },
    { name: "CY" },
    { name: "CZ" },
    { name: "DD" },
    { name: "DE" },
    { name: "DJ" },
    { name: "DK" },
    { name: "DM" },
    { name: "DO" },
    { name: "DZ" },
    { name: "EA" },
    { name: "EC" },
    { name: "EE" },
    { name: "EG" },
    { name: "EM" },
    { name: "ES" },
    { name: "FI" },
    { name: "FR" },
    { name: "GA" },
    { name: "GB" },
    { name: "GC" },
    { name: "GD" },
    { name: "GE" },
    { name: "GH" },
    { name: "GM" },
    { name: "GN" },
    { name: "GQ" },
    { name: "GR" },
    { name: "GT" },
    { name: "GW" },
    { name: "HK" },
    { name: "HN" },
    { name: "HR" },
    { name: "HU" },
    { name: "IB" },
    { name: "ID" },
    { name: "IE" },
    { name: "IL" },
    { name: "IN" },
    { name: "IR" },
    { name: "IS" },
    { name: "IT" },
    { name: "JO" },
    { name: "KE" },
    { name: "KG" },
    { name: "KH" },
    { name: "KM" },
    { name: "KN" },
    { name: "KP" },
    { name: "KW" },
    { name: "KZ" },
    { name: "LA" },
    { name: "LC" },
    { name: "LI" },
    { name: "LK" },
    { name: "LR" },
    { name: "LS" },
    { name: "LT" },
    { name: "LU" },
    { name: "LV" },
    { name: "LY" },
    { name: "MA" },
    { name: "MC" },
    { name: "MD" },
    { name: "ME" },
    { name: "MG" },
    { name: "MK" },
    { name: "ML" },
    { name: "MN" },
    { name: "MO" },
    { name: "MR" },
    { name: "MT" },
    { name: "MW" },
    { name: "MX" },
    { name: "MY" },
    { name: "MZ" },
    { name: "NA" },
    { name: "NE" },
    { name: "NG" },
    { name: "NI" },
    { name: "NL" },
    { name: "NO" },
    { name: "NZ" },
    { name: "OA" },
    { name: "OM" },
    { name: "PA" },
    { name: "PE" },
    { name: "PG" },
    { name: "PH" },
    { name: "PL" },
    { name: "PT" },
    { name: "PY" },
    { name: "QA" },
    { name: "RO" },
    { name: "RS" },
    { name: "RU" },
    { name: "RW" },
    { name: "SA" },
    { name: "SC" },
    { name: "SD" },
    { name: "SE" },
    { name: "SG" },
    { name: "SI" },
    { name: "SK" },
    { name: "SL" },
    { name: "SM" },
    { name: "SN" },
    { name: "ST" },
    { name: "SU" },
    { name: "SV" },
    { name: "SY" },
    { name: "SZ" },
    { name: "TD" },
    { name: "TG" },
    { name: "TH" },
    { name: "TJ" },
    { name: "TM" },
    { name: "TN" },
    { name: "TR" },
    { name: "TT" },
    { name: "TW" },
    { name: "TZ" },
    { name: "UA" },
    { name: "UG" },
    { name: "UY" },
    { name: "UZ" },
    { name: "VC" },
    { name: "VE" },
    { name: "VN" },
    { name: "YU" },
    { name: "ZA" },
    { name: "ZM" },
    { name: "ZW" }
];
exports.getProductDetailsAPIEndPoint = 'bx_block_advanced_search/fetch_product'
exports.getPublicationDetailsAPIEndPoint = 'bx_block_advanced_search/fetch_scholar'
exports.getGoogleSearchDataAPIEndPoint = 'bx_block_advanced_search/google_search_data'
exports.getSearchDetailAPIEndPoint = "bx_block_advanced_search/get_list_patent_data"
exports.getPublicationListAPIEndPoint = "bx_block_advanced_search/get_list_publication_data"
// Customizable Area End